import React from 'react'
import classNames from 'classnames'
import { Badge } from 'components/ui'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'

export const eventColors = {
    teal: { bg: 'bg-teal-200', text: 'text-teal-600', dot: 'bg-teal-600' },
    orange: {
        bg: 'bg-orange-200',
        text: 'text-orange-600',
        dot: 'bg-orange-600',
    },
    default: { bg: 'bg-gray-500', text: 'text-white', dot: 'bg-gray-600' },
}

const CalendarView = ({ wrapperClass, ...props }) => {
    return (
        <div className={classNames('calendar', wrapperClass)}>
            <FullCalendar
                initialView="dayGridMonth"
                // timeZone="local"
                locales={allLocales}
                locale={'sq'}
                selectable={true}
                titleFormat={{ year: 'numeric', month: 'long' }}
                buttonText={{
                    today: 'Sot',
                    month: 'Muaji',
                    week: 'Java',
                    day: 'Dita',
                }}
                headerToolbar={{
                    left: 'title',
                    center: '',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay prev,next',
                }}
                eventContent={(arg) => {
                    const { extendedProps } = arg.event
                    const { isEnd, isStart } = arg

                    const colorClass =
                        extendedProps.eventColor &&
                        eventColors[extendedProps.eventColor]
                            ? eventColors[extendedProps.eventColor]
                            : eventColors.default

                    return (
                        <div
                            className={classNames(
                                'custom-calendar-event p-2 rounded-lg shadow-sm transition-all duration-200 hover:scale-105',
                                colorClass.bg,
                                colorClass.text,
                                isEnd && !isStart && 'rounded-l-none',
                                !isEnd && isStart && 'rounded-r-none'
                            )}
                        >
                            {!(isEnd && !isStart) && (
                                <Badge
                                    className={classNames(
                                        'mr-1 rtl:ml-1',
                                        colorClass.dot
                                    )}
                                />
                            )}
                            {!(isEnd && !isStart) && (
                                <span>{arg.timeText}</span>
                            )}
                            <span className="font-semibold ml-1 rtl:mr-1">
                                {arg.event.title}
                            </span>
                        </div>
                    )
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                {...props}
            />
        </div>
    )
}

export default CalendarView
