import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import mockServer from "./mock";
import appConfig from "configs/app.config";
import "./locales";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Toaster } from "react-hot-toast";

const environment = process.env.NODE_ENV;

if (environment !== "production" && appConfig.enableMock) {
  mockServer({ environment });
}

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      scope="
      https://www.googleapis.com/auth/calendar 
      https://www.googleapis.com/auth/tasks.readonly 
      https://www.googleapis.com/auth/gmail.readonly 
      https://www.googleapis.com/auth/gmail.send 
      https://www.googleapis.com/auth/gmail.compose 
      https://www.googleapis.com/auth/gmail.modify
  "
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <Theme>
              <Layout />
              <Toaster position="top-right" />
            </Theme>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
